<template>
  <div class="guessing">
    <div class="head">
      <Header callBack @click="goBack" v-if="!$route.query.from">
        <template>{{ $route.query.title || "体育赛事竞猜" }}</template>
        <template v-slot:right v-if="token"
          ><span style="color: #0754d3" @click="getPointLog"
            >竞猜记录</span
          ></template
        >
      </Header>
      <div class="swiperBox">
        <!-- <div class="swiperBox" v-if="gameList.length"> -->
        <swiper :options="swiperOption" class="swiper" ref="swiper">
          <swiper-slide
            v-for="item in swiperList"
            :key="item.id"
            class="slideItem"
            :class="item.id === swiperActive ? 'active' : null"
          >
            <span @click="changeSwiperId(item)">{{ item.name }}</span>
          </swiper-slide>
          <div
            class="swiper-button swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button swiper-button-next"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <!-- main -->
    <div class="main" :style="{ top: !$route.query.from ? '100px' : '50px' }">
      <!-- <div
      class="main"
      :class="!gameList.length ? 'noData' : ''"
      :style="{ top: !$route.query.from ? '100px' : '50px' }"
    > -->
      <div class="list">
        <div class="jiaozi" v-if="token">
          <div class="jiaoziLeft" :class="!gameList.length ? 'noData' : ''">
            我的可用交子：{{ point_own }}个
          </div>
          <div class="jiaoziRight" @click="anyRuleInfo">
            <!-- <img
              v-if="gameList.length"
              src="../../assets/images/jcinfo.png"
              alt=""
            />
            <img v-else src="../../../static/image/doubt.png" alt="" /> -->
            <img src="../../../static/image/doubt.png" alt="" />
          </div>
        </div>
        <div
          class="listItem"
          :class="[
            item.guessing === 3
              ? 'listItem1'
              : item.guessing === 2
              ? 'listItem2'
              : null,
            item.game_type === 2
              ? 'basketball'
              : item.game_type === 3
              ? 'other'
              : null,
          ]"
          v-for="item in gameList"
          :key="item.id"
        >
          <div class="header">
            <div class="headerLeft">{{ guessingMap[item.guessing] }}</div>
            <div class="headerMiddle">{{ item.competition_type }}</div>
            <div class="headerRight">{{ item.game_time }}</div>
          </div>
          <div class="middle">
            <div class="middleChild">
              <div class="title">
                <span>{{ item.home_team }}</span> <i class="zhu">主</i>
              </div>
              <div class="subTitle">当前参与交子:{{ item.home_point_own }}</div>
              <div class="subTitle1">MVP: {{ item.mvp_home }}</div>
            </div>
            <div class="middleChild">
              <div class="title">
                <i>客</i><span>{{ item.guest_team }}</span>
              </div>
              <div class="subTitle">
                当前参与交子:{{ item.guest_point_own }}
              </div>
              <div class="subTitle1">MVP: {{ item.mvp_guest }}</div>
            </div>
          </div>
          <div class="fotter" v-if="item.guessing === 1">
            <div
              class="btn"
              :class="item.is_show === 1 ? 'des' : null"
              @click="showCompetition(item.home_team, item.home_team_id, item)"
            >
              竞猜
            </div>
            <div
              class="btn"
              :class="item.is_show === 1 ? 'des' : null"
              @click="
                showCompetition(item.guest_team, item.guest_team_id, item)
              "
            >
              竞猜
            </div>
          </div>
          <div class="fotter" v-if="item.guessing === 3">
            <div class="jiaoziNum">
              获得交子：<span style="font-weight: 500">{{
                item.point_self || 0
              }}</span
              >个
            </div>
          </div>
          <div class="center" v-if="item.guessing === 3">
            <div class="vs">{{ item.score }}</div>
            <div class="let" v-if="item.game_type !== 2">
              让 {{ item.concede_points }}
            </div>
          </div>
          <div class="center" v-else>
            <div class="vs">vs</div>
            <div class="let" v-if="item.game_type !== 2">
              让 {{ item.concede_points }}
            </div>
          </div>
        </div>
        <div v-if="!gameList.length" class="def def1">
          <img src="../../../static/image/emptyBox.png" alt="" />
          <p>更多赛事，敬请期待</p>
        </div>
      </div>
    </div>

    <!-- 竞猜记录 -->
    <van-popup v-model:show="record" class="popUp">
      <div class="header">竞猜记录</div>
      <div class="tab tab1">
        <div class="tabItem">比赛球队</div>
        <div class="tabItem">交子</div>
        <div class="tabItem">结果</div>
      </div>
      <div class="content">
        <div class="tab" v-for="item in logList" :key="item.id">
          <div class="tabItem">
            <span
              :class="item.team_id === item.home_team_id ? 'active' : null"
              >{{ item.home_team }}</span
            >
            vs
            <span
              :class="item.team_id === item.guest_team_id ? 'active' : null"
              >{{ item.guest_team }}</span
            >
          </div>
          <div class="tabItem">{{ item.score }}</div>
          <div
            class="tabItem"
            :class="
              item.score_get && item.score_get.includes('+')
                ? 'plus'
                : item.score_get && item.score_get.includes('-')
                ? 'minus'
                : null
            "
          >
            {{ item.score_get }}
          </div>
        </div>
        <div v-if="!logList.length" class="def">
          <img src="../../../static/image/emptyBox.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <div class="close" @click="record = false"></div>
    </van-popup>

    <!-- 竞猜 -->
    <van-popup v-model:show="competition" class="popUp1">
      <div class="header">竞猜</div>
      <div class="title">{{ teamName }}胜</div>
      <div class="inputGroup">
        <div
          class="left"
          :class="query.point >= 200 ? 'active' : null"
          @click="query.point >= 200 ? (query.point -= 100) : null"
        >
          -
        </div>
        <div class="input">
          <input type="number" v-model="query.point" />
        </div>
        <div
          class="right"
          :class="this.query.point <= this.point_own - 100 ? 'active' : null"
          @click="plus"
        >
          +
        </div>
      </div>
      <div class="info">使用交子数</div>
      <div class="warning">*只能输入100的倍数</div>
      <div class="btn">
        <div
          class="btnLeft btnChild"
          @click="
            competition = false;
            query.point = 100;
          "
        >
          取消
        </div>
        <div class="btnRight btnChild" @click="submit">确定</div>
      </div>
    </van-popup>

    <van-popup v-model:show="showPopUp" class="popUp2">
      <div class="header">{{ ruleInfo.name }}</div>
      <div class="content" v-html="ruleInfo.rule"></div>
      <div class="bottom" @click="showPopUp = false">我知道了</div>
    </van-popup>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import moment from "moment";
import { addBridgeForAndroidWebView } from "sdbridge-android";
export default {
  name: "guessing",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      logList: [],
      logQuery: {
        pagesize: 1000,
        pageindex: 1,
      },
      query: {
        point: 100,
        game_id: "",
        team_id: "",
      },
      teamName: "",
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      bridge: null,
      showPopUp: false,
      swiperOption: {
        allowTouchMove: true,
        slidesPerView: 4,
        spaceBetween: 10,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      day: moment().format("YYYY-MM-DD"),
      record: false,
      competition: false,
      swiperList: [
        // { id: 10, name: "12-22" },
        // { id: 11, name: "12-23" },
        // { id: 12, name: "12-24" },
        // { id: 13, name: "12-25" },
        // { id: 14, name: "12-26" },
        // { id: 15, name: "12-27" },
        // { id: 16, name: "12-28" },
        // { id: 17, name: "12-29" },
        // { id: 18, name: "12-30" },
        // { id: 19, name: "12-31" },
        // { id: 20, name: "01-01" },
        // { id: 21, name: "01-02" },
        // { id: 22, name: "01-03" },
        // { id: 23, name: "01-04" },
        // { id: 24, name: "01-05" },
        // { id: 25, name: "01-06" },
        // { id: 26, name: "01-07" },
        // { id: 27, name: "01-08" },
        // { id: 28, name: "01-09" },
        // { id: 29, name: "01-10" },
        // { id: 30, name: "01-11" },
        // { id: 31, name: "01-12" },
        // { id: 32, name: "01-13" },
        // { id: 33, name: "01-14" },
        // { id: 34, name: "01-15" },
        // { id: 35, name: "01-16" },
        // { id: 36, name: "01-17" },
        // { id: 37, name: "01-18" },
        // { id: 38, name: "01-19" },
        // { id: 39, name: "01-20" },
        // { id: 40, name: "01-21" },
        // { id: 41, name: "01-22" },
        // { id: 42, name: "01-23" },
        // { id: 43, name: "01-24" },
        // { id: 44, name: "01-25" },
        // { id: 45, name: "01-26" },
        // { id: 46, name: "01-27" },
        // { id: 47, name: "01-28" },
        // { id: 48, name: "01-29" },
        // { id: 49, name: "01-30" },
        // { id: 50, name: "01-31" },
        // { id: 51, name: "02-01" },
        // { id: 52, name: "02-02" },
        // { id: 53, name: "02-03" },
        // { id: 54, name: "02-04" },
        // { id: 55, name: "02-05" },
        // { id: 56, name: "02-06" },
        // { id: 57, name: "02-07" },
        // { id: 58, name: "02-08" },
        // { id: 59, name: "02-09" },
        // { id: 60, name: "02-10" },
        // { id: 61, name: "02-11" },
        // { id: 62, name: "02-12" },
        // { id: 63, name: "02-13" },
        // { id: 64, name: "02-14" },
        // { id: 65, name: "02-15" },
        // { id: 66, name: "02-16" },
        // { id: 67, name: "02-17" },
        // { id: 68, name: "02-18" },
        // { id: 69, name: "02-19" },
        // { id: 70, name: "02-20" },
        // { id: 71, name: "02-21" },
        // { id: 72, name: "02-22" },
        // { id: 73, name: "02-23" },
        // { id: 74, name: "02-24" },
        // { id: 75, name: "02-25" },
        // { id: 76, name: "02-26" },
        // { id: 77, name: "02-27" },
        // { id: 78, name: "02-28" },
        // { id: 79, name: "03-01" },
        // { id: 80, name: "03-02" },
        // { id: 81, name: "03-03" },
        // { id: 82, name: "03-04" },
        // { id: 83, name: "03-05" },
        // { id: 84, name: "03-06" },
        // { id: 85, name: "03-07" },
        // { id: 86, name: "03-08" },
        // { id: 87, name: "03-09" },
        // { id: 88, name: "03-10" },
        // { id: 89, name: "03-11" },
        // { id: 90, name: "03-12" },
        // { id: 91, name: "03-13" },
        // { id: 92, name: "03-14" },
        // { id: 93, name: "03-15" },
        // { id: 94, name: "03-16" },
        // { id: 95, name: "03-17" },
        // { id: 96, name: "03-18" },
        // { id: 97, name: "03-19" },
        // { id: 98, name: "03-20" },
        // { id: 99, name: "03-21" },
        // { id: 100, name: "03-22" },
        // { id: 101, name: "03-23" },
        // { id: 102, name: "03-24" },
        // { id: 103, name: "03-25" },
        // { id: 104, name: "03-26" },
        // { id: 105, name: "03-27" },
        // { id: 106, name: "03-28" },
        // { id: 107, name: "03-29" },
        // { id: 108, name: "03-30" },
        // { id: 109, name: "03-31" },
        // { id: 110, name: "04-01" },
        // { id: 111, name: "04-02" },
        // { id: 112, name: "04-03" },
        // { id: 113, name: "04-04" },
        // { id: 114, name: "04-05" },
        // { id: 115, name: "04-06" },
        // { id: 116, name: "04-07" },
        // { id: 117, name: "04-08" },
        // { id: 118, name: "04-09" },
        // { id: 119, name: "04-10" },
        // { id: 120, name: "04-11" },
        // { id: 121, name: "04-12" },
        // { id: 122, name: "04-13" },
        // { id: 123, name: "04-14" },
        // { id: 124, name: "04-15" },
        // { id: 125, name: "04-16" },
        // { id: 126, name: "04-17" },
        // { id: 127, name: "04-18" },
        // { id: 128, name: "04-19" },
        // { id: 129, name: "04-20" },
        // { id: 130, name: "04-21" },
        // { id: 131, name: "04-22" },
        // { id: 132, name: "04-23" },
        // { id: 133, name: "04-24" },
        // { id: 134, name: "04-25" },
        // { id: 135, name: "04-26" },
        // { id: 136, name: "04-27" },
        // { id: 137, name: "04-28" },
        // { id: 138, name: "04-29" },
        // { id: 139, name: "04-30" },
        // { id: 140, name: "05-01" },
        // { id: 141, name: "05-02" },
        // { id: 142, name: "05-03" },
        // { id: 143, name: "05-04" },
        // { id: 144, name: "05-05" },
        // { id: 145, name: "05-06" },
        // { id: 146, name: "05-07" },
        // { id: 147, name: "05-08" },
      ],
      swiperActive: 0,
      token: null,
      gameList: [],
      point_own: 0,
      guessingMap: {
        1: "竞猜中",
        2: "竞猜结束",
        3: "比赛结束",
      },
      ruleInfo: "",
      timer: null,
    };
  },
  async mounted() {
    // this.setSwiper();
    this.token = this.$route.query.token;
    await this.getTime();
    this.setSwiper();
    this.gamelist(
      this.swiperList.find((e) => e.id === this.swiperActive).name,
      true
    );
    this.setTime();
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log("TypeScript正在尝试挂载");
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          "window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!"
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log("window.WebViewJavascriptBridge 挂载成功！！");
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    async getTime() {
      const e = await this.$api.getGameDate();
      this.swiperList = e.data.map((e, k) => ({ id: k, name: e }));
    },
    setSwiper() {
      if (this.$refs.swiper) {
        const data = moment().format("MM-DD");
        this.swiperActive =
          (this.swiperList.find((e) => e.name === data) || {}).id || 0;

        this.$refs.swiper.swiper.slideTo(
          this.swiperList.findIndex((e) => e.name === data)
        );
      }
    },
    setTime() {
      this.timer = setInterval(() => {
        try {
          this.gameList.forEach((e) => {
            const time = moment(`${this.day} ${e.game_time}`).unix();
            if (time === moment().unix()) {
              const item = this.swiperList.find(
                (e) => e.id === this.swiperActive
              );
              this.getTeamCardgameStake(item.name);
            }
          });
        } catch (error) {
          //
        }
      }, 1000);
    },
    // 获取规则
    anyRuleInfo() {
      this.showPopUp = true;
      this.$api.anyRuleInfo({ type: "team_guess_rule" }).then((e) => {
        if (e.code === 0) {
          this.ruleInfo = e.data;
        }
      });
    },
    // 获取精彩记录
    getPointLog() {
      this.record = true;
      const query = {
        ...this.logQuery,
        token: this.token,
      };
      this.$api.pointLog(query).then((e) => {
        if (e.code === 0) {
          this.logList = e.data;
        } else {
          this.$toast(e.msg);
        }
      });
    },
    submit() {
      if (this.query.point == 0 || !this.query.point) {
        return this.$toast("请输入交子数量");
      }
      if (this.query.point % 100 !== 0) {
        return this.$toast("请输入100的倍数");
      }
      if (Number(this.query.point) > this.point_own) {
        return this.$toast("可用交子不足");
      }
      const query = {
        ...this.query,
        token: this.token,
      };
      this.$api.guessingPoint(query).then((e) => {
        if (e.code === 0) {
          this.competition = false;
          this.query.point = 100;
          const item = this.swiperList.find((v) => v.id === this.swiperActive);
          this.gamelist(item.name);
        }
        this.$toast(e.msg);
      });
    },
    plus() {
      if (this.query.point <= this.point_own - 100) {
        this.query.point = parseInt(this.query.point) + 100;
      }
    },
    // 获取比赛列表
    gamelist(day, isInit) {
      const query = {
        day,
      };
      if (this.token) {
        query.token = this.token;
      }
      this.$api.gamelist(query).then((e) => {
        if (e.code === 0) {
          this.gameList = e.data;
          this.point_own = e.point_own;
          if (isInit) {
            this.$nextTick(() => {
              this.setSwiper();
            });
          }
          // this.setTime()
        } else {
          this.$toast(e.msg);
        }
      });
    },
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        } else if (this.isiOS) {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        }
      } else {
        this.$router.back();
      }
    },
    showCompetition(name, id, item) {
      if (item.is_show == 1) return false;
      if (!this.token) {
        // 如果是app 无token 跳转至登录页面
        if (this.$route.query.platform) {
          if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(JSON.stringify({ key: -1 }));
          } else if (this.isiOS) {
            this.bridge.callHandler(
              "jumptoapp",
              { key: -1 },
              function (response) {
                console.log(JSON.stringify(response));
              }
            );
          }
          return;
        }
        return this.$router.push("/login");
      }
      this.teamName = name;
      this.query.team_id = id;
      this.query.game_id = item.id;
      this.competition = true;
    },
    changeSwiperId(item) {
      this.swiperActive = item.id;
      this.gamelist(item.name);
    },
  },
};
</script>

<style scoped lang="less">
.guessing {
  .main {
    padding-top: 15px;
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../../assets/images/jcbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    &.noData {
      background: none;
      padding-top: 0;
      top: 55px !important;
    }
    .list {
      height: 100%;
      padding: 0 0 20px;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      .jiaozi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .jiaoziLeft {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          // &.noData {
          //   color: #0754d3;
          // }
        }
        .jiaoziRight {
          img {
            width: 17px;
          }
        }
      }
      .listItem {
        background: url(../../assets/images/jcbg1.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        width: 361px;
        height: 200px;
        margin: 0 auto;
        padding: 15px 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 95%;
          .headerLeft {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff31d;
            flex: 1;
          }
          .headerMiddle {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            flex: 1;
            text-align: center;
            padding: 0 20px;
            white-space: nowrap;
            min-width: 1px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .headerRight {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #51a452;
            flex: 1;
            text-align: right;
          }
        }
        .middle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .middleChild {
            text-align: center;
            width: 140px;
            .title {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 20px;
              span {
                flex: 1;
                min-width: 1px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              i {
                font-size: 12px;
                border: 1px solid #fff;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                transform: scale(0.75);
                &.zhu {
                  border: 1px solid #fef883;
                  color: #fef883;
                }
              }
            }
            .subTitle {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: 400;
              margin-top: 15px;
              color: #d9ffd9;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .subTitle1 {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: 400;
              margin-top: 5px;
              color: #fff883;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .fotter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;
          padding: 0 20px;
          box-sizing: border-box;
          .btn {
            width: 80px;
            height: 33px;
            border: 1px solid #00561f;
            background: linear-gradient(0deg, #fff100 0%, #fff888 100%);
            border-radius: 5px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #135f20;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 3px 3px 0 #00561f;
            &.des {
              background: #bfbfbf;
              color: #fff;
            }
          }
        }
        .center {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin-top: -6px;
          text-align: center;
          .vs {
            font-size: 25px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            height: 35px;
            line-height: 35px;
          }
          .let {
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #94ff96;
          }
        }
        &.basketball {
          background: url(../../assets/images/jcbg1Bask1.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          .btn {
            border: 1px solid #a63919;
            box-shadow: 3px 3px 0 #a63919;
            color: #a63919;
          }
          .headerRight {
            color: #ffae95;
          }
          .subTitle {
            color: #ffe5d9 !important;
          }
          // .subTitle1 {
          //   color: #a63919 !important;
          // }
          .let {
            color: #fff;
          }
          .vs {
            color: #fff77c !important;
          }
        }
        &.other {
          background: url(../../assets/images/jcbgOther1.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          .btn {
            border: 1px solid #a63919;
            box-shadow: 3px 3px 0 #a63919;
            color: #a63919;
          }
          .headerRight {
            color: #ffae95;
          }
          .subTitle {
            color: #ffe5d9 !important;
          }
          .let {
            color: #fff;
          }
          .vs {
            color: #fff77c !important;
          }
          .fotter {
            .btn {
              border: 1px solid #370556;
              color: #370556;
              box-shadow: 3px 3px 0 #370556;
              &.des {
                background: #bfbfbf;
                color: #fff;
              }
            }
          }
        }
        &.listItem1 {
          background: url(../../assets/images/jcbg2.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          height: 175px;
          &.basketball {
            background: url(../../assets/images/jcbg1Bask2.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
          }
          &.other {
            background: url(../../assets/images/jcbgOther2.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
          }
          .fotter {
            margin-bottom: 8px;
            .jiaoziNum {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              width: 100%;
            }
          }
        }
        &.listItem2 {
          background: url(../../assets/images/jcbg3.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          height: 147px;
          &.basketball {
            background: url(../../assets/images/jcbg1Bask3.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
          }
          &.other {
            background: url(../../assets/images/jcbgOther3.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
          }
          .middle {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .head {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    .swiperBox {
      position: relative;
      padding: 0 35px;
      margin-top: 5px;
      .swiper {
        position: unset;
        .slideItem {
          text-align: center;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          padding: 5px 0;
          color: #333333;
          span {
            border-bottom: 3px solid transparent;
          }
          &.active {
            color: #2a922a;
            span {
              border-bottom: 3px solid #2a922a;
            }
          }
        }
      }
      .swiper-button {
        width: 10px;
        height: 10px;
        background-size: auto;
        margin: 0;
        transform: translateY(-50%);
        background: none;
        &.swiper-button-prev {
          left: 25px;
          border-top: 2.5px solid #238327;
          border-left: 2.5px solid #238327;
          transform: rotate(-45deg) translateY(-50%);
          &.swiper-button-disabled {
            opacity: 1;
            border-top: 2.5px solid #999999;
            border-left: 2.5px solid #999999;
          }
        }
        &.swiper-button-next {
          right: 25px;
          border-top: 2.5px solid #238327;
          border-right: 2.5px solid #238327;
          transform: rotate(45deg) translateY(-50%);
          &.swiper-button-disabled {
            opacity: 1;
            border-top: 2.5px solid #999999;
            border-right: 2.5px solid #999999;
          }
        }
      }
    }
  }
  .popUp {
    width: 80%;
    padding: 18px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 280px;
    display: flex;
    flex-direction: column;
    overflow: visible;
    .close {
      width: 27px;
      height: 27px;
      border: 1px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 100%;
      left: 50%;
      margin-top: 40px;
      transform: rotate(45deg) translateX(-50%);
      &::before {
        display: block;
        content: "";
        width: 80%;
        height: 2px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &::after {
        display: block;
        content: "";
        height: 80%;
        width: 2px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        visibility: visible;
      }
    }
    .header {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      text-align: center;
      margin-bottom: 16px;
    }

    .tab {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .tabItem {
        color: #333333;
        flex: 1;
        font-size: 12px;
        text-align: center;
        &:first-child {
          flex: 2;
        }
        .active {
          color: #2a922a;
        }
        &.minus {
          color: #009944;
        }
        &.plus {
          color: #e60012;
        }
      }
      &.tab1 {
        .tabItem {
          font-size: 14px;
        }
      }
    }
    .content {
      min-height: 1px;
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 0 -18px;
      padding: 0 18px;
    }
  }
  .popUp1 {
    width: 80%;
    padding: 18px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .header {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      text-align: center;
      margin-bottom: 16px;
    }
    .title {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      text-align: center;
      color: #2a922a;
    }
    .inputGroup {
      display: flex;
      align-items: center;
      width: 90%;
      background: #f8f8f8;
      border-radius: 20px;
      margin: 16px auto 0;
      .left {
        border-right: 1px solid #e5e5e5;
        font-size: 25px;
        color: #cecece;
        width: 35px;
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        &.active {
          color: #2a922a;
        }
      }
      .input {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          border: none;
          outline: none;
          background: transparent;
          width: 100%;
          font-size: 19px;
          color: #2a922a;
          text-align: center;
        }
      }
      .right {
        border-left: 1px solid #e5e5e5;
        font-size: 25px;
        color: #cecece;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        &.active {
          color: #2a922a;
        }
      }
    }
    .info {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin: 10px 0 5px;
    }
    .warning {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ff293a;
      text-align: center;
    }
    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: 20px auto 0;
      .btnChild {
        width: 90px;
        height: 32px;
        border: 1px solid #2a922a;
        border-radius: 2px;
        color: #2a922a;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        &.btnRight {
          background: #2a922a;
          color: #ffffff;
        }
      }
    }
  }
}
.popUp2 {
  width: 80%;
  border-radius: 4px;
  .header {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 20px 0 15px;
  }
  .content {
    margin: 15px 0;
    overflow: auto;
    margin: 0.4rem 0;
    max-height: 60vh;
    min-height: 20vh;
    height: 40vh;
    padding: 0 20px;
    font-size: 16px;
  }
  .bottom {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0754d3;
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ececec;
  }
}
.def {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  img {
    width: 30%;
    margin-right: -20px;
  }
  p {
    font-size: 14px;
    color: #aaa;
  }
  &.def1 {
    margin-top: 100px;
  }
}
</style>
